import React from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import './App.scss';
import { observer, useLocalStore } from 'mobx-react';
import { AuthStore } from './stores/AuthStore';

const loading = () => <div className="animated fadeIn pt-3 text-center">Yükleniyor...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));
const DeltaContext = React.createContext();

const PrivateRoute = observer(function PrivateRoute({ isAuth, children }) {
  const store = useLocalStore(AuthStore);

  if (!store.isAuth) {
    return <Redirect to="/login" />
  }
  return children;
})
const App = observer(function App() {
  const store = useLocalStore(AuthStore);
  return (
    <DeltaContext.Provider value={store}>
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
            <PrivateRoute >
              <Route path="/" name="Home" render={props => <DefaultLayout {...props} />} />
            </PrivateRoute>
          </Switch>
        </React.Suspense>
      </HashRouter>
    </DeltaContext.Provider>

  )
})

export default App;
