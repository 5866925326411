import { observable, action } from "mobx";
import JwtDecode from "jwt-decode";
import axios from "../helpers/axios-instance";
export const AuthStore = () => {
  const store = observable({
    token: localStorage.token,
    user: {},
    get userName() {
      return store.user.name
    },
    get isAuth() {
      if (store.token === undefined) {
        return false;
      }
      const { exp } = JwtDecode(store.token);
      if (exp < new Date().getTime() / 1000) {
        store.logOut();
        return false;
      }
      return true;
    },
    setToken: action((token) => {
      localStorage.token = token;
      let userName = JwtDecode(token).unique_name;
      store.user.name = userName;
      localStorage.setItem("userName", userName);
      store.token = localStorage.token;
      axios.defaults.headers.common = { "Authorization": `Bearer ${token}` }

    }),
    logOut: action(() => {
      localStorage.removeItem("token");
      localStorage.removeItem("userName");
      store.token = "";
      store.user = {};

    })
  });
  return store;
}
