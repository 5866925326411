import axios from "axios";
import { baseUrl } from "./helper";
import { toast } from "react-toastify";

axios.defaults.baseURL = baseUrl;
axios.defaults.headers.common = { "Authorization": `Bearer ${localStorage.token}` }

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (error.response) {
    if (error.response.status === 401) {
      window.location.href = "#/login";
    } else {
      toast.dismiss();
      toast.error("Hata oluştu.", {
        duration: 5000
      });
    }
  }
  return Promise.reject(error);
});
export default axios;
